/* General
*/
#app_wrapper {
  height: inherit;
}

.page-content {
  min-height: calc(100% - 66px);
  background-color: #fefefe;
}

/* Home Header
*/
#home-header {
  min-height: calc(100vh);
  padding: 1em 0em;
  position: relative;
}

#home-header-prime-logo {
  width: 50%;
  max-width: 400px;
  min-width: 250px;
  margin-bottom: 32px;
  -webkit-filter: drop-shadow(0px 0px 3px black);
  filter: drop-shadow(0px 0px 3px black);
}

.home-scroll-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
}

.home-header-image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-image: url(../../assets/Mayfair_to_Belgravia_210mm_c1.jpg);
  background-size: cover;
  background-position: center top;
}

.header-abstract {
  padding: 10px;
  -webkit-filter: drop-shadow(0px 0px 3px black);
  filter: drop-shadow(0px 0px 3px black);
}

@media only screen and (max-height: 550px) {
  #home-header-prime-logo {
    min-width: 160px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-height: 550px) {
  #home-header-prime-logo {
    min-width: 160px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 781px) {
  .header-abstract {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
