/* Footer
*/
.default-footer {
  border-top: 1px solid #fff;
  background-color: rgb(13, 13, 29);
  background-size: 100% auto;
  background-position: 50% 50%;
  padding: 5em 0;
  border: 0;
  color: white;
  text-align: justify;
  position: relative;
  z-index: 1000;
}

.footer {
  font-size: 12px;
  width: 100%;
}

.footer a {
  text-decoration: underline;
}

.footer .column.left {
  text-align: left;
}
.footer .column.center {
  text-align: center;
}
.footer .column.right {
  text-align: right;
}

/* Footer
*/
.vx-logo {
  text-align: center;
}
.vx-logo img {
  height: 70px;
  height: 70px;
  margin: 0px 5% 40px 5%;
}

.footer-note,
.footer-note-right {
  text-decoration: underline;
}

.footer-note-right {
  margin-left: 20px;
}

.footer-list {
  list-style-type: none;
  padding: 0;
}

.footer-list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em 0;
}

.footer-list li img {
  height: auto;
  width: 24px;
  margin-right: 0.5em;
}

.footer-list li a {
  color: #fff;
}

.footer-list li a:hover {
  text-decoration: underline;
}
