html, body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}

.router-wrapper {
  height: inherit;
}

/* Footer
*/
.default-footer {
  border-top: 1px solid #fff;
  background-color: rgb(13, 13, 29);
  background-size: 100% auto;
  background-position: 50% 50%;
  padding: 5em 0;
  border: 0;
  color: white;
  text-align: justify;
  position: relative;
  z-index: 1000;
}

.footer {
  font-size: 12px;
  width: 100%;
}

.footer a {
  text-decoration: underline;
}

.footer .column.left {
  text-align: left;
}
.footer .column.center {
  text-align: center;
}
.footer .column.right {
  text-align: right;
}

/* Footer
*/
.vx-logo {
  text-align: center;
}
.vx-logo img {
  height: 70px;
  height: 70px;
  margin: 0px 5% 40px 5%;
}

.footer-note,
.footer-note-right {
  text-decoration: underline;
}

.footer-note-right {
  margin-left: 20px;
}

.footer-list {
  list-style-type: none;
  padding: 0;
}

.footer-list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em 0;
}

.footer-list li img {
  height: auto;
  width: 24px;
  margin-right: 0.5em;
}

.footer-list li a {
  color: #fff;
}

.footer-list li a:hover {
  text-decoration: underline;
}

/* General
*/
#app_wrapper {
  height: inherit;
}

.page-content {
  min-height: calc(100% - 66px);
  background-color: #fefefe;
}

/* Home Header
*/
#home-header {
  min-height: calc(100vh);
  padding: 1em 0em;
  position: relative;
}

#home-header-prime-logo {
  width: 50%;
  max-width: 400px;
  min-width: 250px;
  margin-bottom: 32px;
  -webkit-filter: drop-shadow(0px 0px 3px black);
  filter: drop-shadow(0px 0px 3px black);
}

.home-scroll-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  cursor: pointer;
}

.home-header-image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-image: url(../../static/media/Mayfair_to_Belgravia_210mm_c1.4e97ae18.jpg);
  background-size: cover;
  background-position: center top;
}

.header-abstract {
  padding: 10px;
  -webkit-filter: drop-shadow(0px 0px 3px black);
  filter: drop-shadow(0px 0px 3px black);
}

@media only screen and (max-height: 550px) {
  #home-header-prime-logo {
    min-width: 160px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-height: 550px) {
  #home-header-prime-logo {
    min-width: 160px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 781px) {
  .header-abstract {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

